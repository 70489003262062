import React from "react";
import {
  ServicePanelSplit,
  ServiceDescriptionRow,
  PanelRows,
  PanelInputRows,
} from "../Common/ServiceItem";
import { useSelector, useDispatch } from "react-redux";
import { conformToCurrency } from "../Common/Currency";
import { AddToVRExperienceAmount } from "./VRExperiencesSlice";
import img from "../../Images/VirtualExperiences.png";
import { ImgWrapper } from "../../Images/index";

export const VRExperiencesPanel = () => {
  const { VRExperienceQuantity, oneTimeDevelopmentCost, monthlyFee, total } =
    useSelector((state) => state.VRExperienceReducer);
  const dispatch = useDispatch();
  const PanelArray = [
    {
      label: "One time development Cost",
      value: conformToCurrency(oneTimeDevelopmentCost),
    },
    {
      label: "Monthly fee",
      value: conformToCurrency(monthlyFee),
    },
    {
      label: "Total Set-up Cost & Annual Syndication Cost",
      value: conformToCurrency(total),
    },
  ];

  return (
    <>
      <PanelInputRows
        PanelArray={[
          {
            label: "Number of Virtual Experience including up to 10 products",
            value: VRExperienceQuantity,
            onChange: (ev) =>
              dispatch(AddToVRExperienceAmount(ev.target.value)),
          },
        ]}
      />
      <PanelRows PanelArray={PanelArray} />
    </>
  );
};

export const VRExperiences = () => {
  return (
    <div>
      <ServiceDescriptionRow>
        Immerse your shoppers in an immersive and contextual Virtual Experience
        where the shopper can visualise up to 10 of our products.
      </ServiceDescriptionRow>
      <ServicePanelSplit
        item1={<ImgWrapper src={img} />}
        item2={<VRExperiencesPanel />}
      />
    </div>
  );
};
