import { createSlice } from "@reduxjs/toolkit";
import { updateWindowPayload } from "../../../libs/ekyCloudMail";

const initialState = {
  brand: "",
  contactName: "",
  email: "",
  phoneNumber: "",
};

const RecommendedSlice = createSlice({
  name: "RecommendedPage",
  initialState,
  reducers: {
    changeBrand(state, amount) {
      state.brand = amount.payload;

      //
      //  Update window payload
      updateWindowPayload("details", "Brand", state.brand);
    },
    changeContactName(state, amount) {
      state.contactName = amount.payload;

      //
      //  Update window payload
      updateWindowPayload("details", "Contact name", state.contactName);
    },
    changeEmail(state, amount) {
      state.email = amount.payload;

      //
      //  Update window payload
      updateWindowPayload("details", "Email", state.email);
    },
    changeNumber(state, amount) {
      state.phoneNumber = amount.payload;

      //
      //  Update window payload
      updateWindowPayload("details", "Phone number", state.phoneNumber);
    },
  },
});

export const { changeBrand, changeContactName, changeEmail, changeNumber } =
  RecommendedSlice.actions;
export default RecommendedSlice.reducer;
