import {
  ServiceDescriptionRow,
  ServicePanelSplit,
  PanelRows,
  PanelInputRows,
} from "../Common/ServiceItem";
import { useSelector, useDispatch } from "react-redux";
import { conformToCurrency } from "../Common/Currency";
import { AddAvatarQuantity } from "./ARAvatarSlice";
import img from "../../Images/ARAvatar-1.png";
import { ImgWrapper } from "../../Images/index";

const ARShopInShopPanel = () => {
  const { ARAvatarCost, ARAvatarQuantity, syndicationCost, total } =
    useSelector((state) => state.ARAvatarReducer);
  const dispatch = useDispatch();

  const PanelInput = [
    {
      label: "Number of AR Avatars to Produce",
      value: ARAvatarQuantity,
      onChange: (ev) => {
        dispatch(AddAvatarQuantity(ev.target.value));
      },
    },
  ];
  const PanelArray = [
    {
      label: "Cost per AR Avatar Production",
      value: conformToCurrency(ARAvatarCost),
    },
    {
      label: "Annual Syndication Cost",
      value: conformToCurrency(syndicationCost),
    },
    {
      label: "Total Cost",
      value: conformToCurrency(total),
    },
  ];
  return (
    <>
      <PanelInputRows PanelArray={PanelInput} />
      <PanelRows PanelArray={PanelArray} />
    </>
  );
};

export const ARAvatar = () => {
  return (
    <div>
      <ServiceDescriptionRow>
        Produce and syndicate an AR Avatar for your products. The AR Avatar can
        be deployed on an unlimited number of products on the site and will be
        deployed in you AR Shop in Shop if you have one.
      </ServiceDescriptionRow>
      <ServicePanelSplit
        item1={<ImgWrapper src={img} />}
        item2={<ARShopInShopPanel />}
      />
    </div>
  );
};
