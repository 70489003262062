import {
  ServiceDescriptionRow,
  ServicePanelSplit,
  PanelRows,
  PanelInputRows,
} from "../Common/ServiceItem";
import { useSelector, useDispatch } from "react-redux";
import { conformToCurrency } from "../Common/Currency";
import { AddToAgentQuantity } from "./OneToOneLiveVideoShoppingSlice";

import img from "../../Images/OneToOneLiveVideoShopping-1.png";
import { ImgWrapper } from "../../Images/index";

export const OneToOneLiveVideoShoppingPanel = () => {
  const { agentQuantity, setupCostPerAgent, monthlyCostPerAgent, total } =
    useSelector((state) => state.OneToOneLiveVideoShoppingReducer);

  const PanelArray = [
    {
      label: "Set-up cost per agent",
      value: conformToCurrency(setupCostPerAgent),
    },
    {
      label: "Monthly cost per agent",
      value: conformToCurrency(monthlyCostPerAgent),
    },
    {
      label: "Total Set-up Cost & Annual Syndication Cost",
      value: conformToCurrency(total),
    },
  ];
  const dispatch = useDispatch();

  return (
    <>
      <PanelInputRows
        PanelArray={[
          {
            label: "Number of agents",
            value: agentQuantity,
            onChange: (ev) => dispatch(AddToAgentQuantity(ev.target.value)),
          },
        ]}
      />
      <PanelRows PanelArray={PanelArray} />
    </>
  );
};

export const OneToOneLiveVideoShopping = () => {
  return (
    <div>
      <ServiceDescriptionRow>
        Enable your shoppers to speak to one of your experts on a one way live
        video call. The live video service will be enable on your selected
        product pages on the site and will also be enabled in your AR Shop in
        Shop (if you have one) in the AR Showcase
      </ServiceDescriptionRow>
      <ServicePanelSplit
        item1={<ImgWrapper src={img} />}
        item2={<OneToOneLiveVideoShoppingPanel />}
      />
    </div>
  );
};
