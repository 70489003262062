import { createSlice } from "@reduxjs/toolkit";
import {
  formatNmber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";

const initialState = {
  VRExperienceQuantity: 0,
  oneTimeDevelopmentCost: 19999,
  monthlyFee: 999,
  total: 0,
};

updateWindowPayload("services", "VR Experience", {
  total: formatNmber(initialState.total),
  VRExperienceQuantity: initialState.VRExperienceQuantity,
});

const VRExperienceSlice = createSlice({
  name: "VRExperience",
  initialState,
  reducers: {
    AddToVRExperienceAmount: (state, amount) => {
      state.VRExperienceQuantity = amount.payload;
      state.total =
        state.VRExperienceQuantity * state.monthlyFee +
        state.oneTimeDevelopmentCost;

      //
      //  Update the window payload
      updateWindowPayload("services", "VR Experience", {
        total: formatNmber(state.total),
        VRExperienceQuantity: state.VRExperienceQuantity,
      });
    },
  },
});

export const { AddToVRExperienceAmount } = VRExperienceSlice.actions;
export default VRExperienceSlice.reducer;
