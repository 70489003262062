import { createSlice } from "@reduxjs/toolkit";
import {
  formatNmber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";

const initialState = {
  total: 0,
  monthlyFee: 4999,
  monthQuantity: 0,
  selectedMonths: [],
};

updateWindowPayload("services", "Home Page Banner", {
  total: formatNmber(initialState.total),
  monthlyFee: formatNmber(initialState.monthlyFee),
  monthQuantity: initialState.monthQuantity,
  selectedMonths: initialState.selectedMonths,
});

const counterSlice = createSlice({
  name: "homePageReducer",
  initialState,
  reducers: {
    AddSelectedMonths(state, amount) {
      state.selectedMonths = amount.payload;
      state.monthQuantity = amount.payload.length;
      state.total = state.monthQuantity * state.monthlyFee;

      //
      //  Update the window payload
      updateWindowPayload("services", "Home Page Banner", {
        total: formatNmber(state.total),
        monthlyFee: formatNmber(state.monthlyFee),
        monthQuantity: state.monthQuantity,
        selectedMonths: state.selectedMonths,
      });
    },
  },
});

export const { AddSelectedMonths } = counterSlice.actions;
export default counterSlice.reducer;
