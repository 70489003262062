import { conformToCurrency } from "../pages/pricingCalculator/v2/Common/Currency";

function camelCaseToTitleCase(in_camelCaseString) {
  var result = in_camelCaseString // "__ToGetYourGEDInTimeASongAboutThe26ABCsIsOfTheEssenceButAPersonalIDCardForUser_456InRoom26AContainingABC26TimesIsNotAsEasyAs123ForC3POOrR2D2Or2R2D"
    .replace(/(_)+/g, " ") // " ToGetYourGEDInTimeASongAboutThe26ABCsIsOfTheEssenceButAPersonalIDCardForUser 456InRoom26AContainingABC26TimesIsNotAsEasyAs123ForC3POOrR2D2Or2R2D"
    .replace(/([a-z])([A-Z][a-z])/g, "$1 $2") // " To Get YourGEDIn TimeASong About The26ABCs IsOf The Essence ButAPersonalIDCard For User456In Room26AContainingABC26Times IsNot AsEasy As123ForC3POOrR2D2Or2R2D"
    .replace(/([A-Z][a-z])([A-Z])/g, "$1 $2") // " To Get YourGEDIn TimeASong About The26ABCs Is Of The Essence ButAPersonalIDCard For User456In Room26AContainingABC26Times Is Not As Easy As123ForC3POOr R2D2Or2R2D"
    .replace(/([a-z])([A-Z]+[a-z])/g, "$1 $2") // " To Get Your GEDIn Time ASong About The26ABCs Is Of The Essence But APersonal IDCard For User456In Room26AContainingABC26Times Is Not As Easy As123ForC3POOr R2D2Or2R2D"
    .replace(/([A-Z]+)([A-Z][a-z][a-z])/g, "$1 $2") // " To Get Your GEDIn Time A Song About The26ABCs Is Of The Essence But A Personal ID Card For User456In Room26A ContainingABC26Times Is Not As Easy As123ForC3POOr R2D2Or2R2D"
    .replace(/([a-z]+)([A-Z0-9]+)/g, "$1 $2") // " To Get Your GEDIn Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456In Room 26A Containing ABC26Times Is Not As Easy As 123For C3POOr R2D2Or 2R2D"

    // Note: the next regex includes a special case to exclude plurals of acronyms, e.g. "ABCs"
    .replace(/([A-Z]+)([A-Z][a-rt-z][a-z]*)/g, "$1 $2") // " To Get Your GED In Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456In Room 26A Containing ABC26Times Is Not As Easy As 123For C3PO Or R2D2Or 2R2D"
    .replace(/([0-9])([A-Z][a-z]+)/g, "$1 $2") // " To Get Your GED In Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456In Room 26A Containing ABC 26Times Is Not As Easy As 123For C3PO Or R2D2Or 2R2D"

    // Note: the next two regexes use {2,} instead of + to add space on phrases like Room26A and 26ABCs but not on phrases like R2D2 and C3PO"
    .replace(/([A-Z]{2,})([0-9]{2,})/g, "$1 $2") // " To Get Your GED In Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456 In Room 26A Containing ABC 26 Times Is Not As Easy As 123 For C3PO Or R2D2 Or 2R2D"
    .replace(/([0-9]{2,})([A-Z]{2,})/g, "$1 $2") // " To Get Your GED In Time A Song About The 26 ABCs Is Of The Essence But A Personal ID Card For User 456 In Room 26A Containing ABC 26 Times Is Not As Easy As 123 For C3PO Or R2D2 Or 2R2D"
    .trim(); // "To Get Your GED In Time A Song About The 26 ABCs Is Of The Essence But A Personal ID Card For User 456 In Room 26A Containing ABC 26 Times Is Not As Easy As 123 For C3PO Or R2D2 Or 2R2D"
  // capitalize the first letter
  return result.charAt(0).toUpperCase() + result.slice(1);
}

const convertObjectToTitleCase = (objectToConvert) => {
  const entries = Object.entries(objectToConvert);
  let newObj = {};
  entries.forEach(([k, v]) => {
    Object.assign(newObj, { [camelCaseToTitleCase(k)]: v });
  });
  return newObj;
};

export const updateWindowPayload = (type, service, object) => {
  //
  //  :step 1:
  //  Make sure we have the payload object in window
  if (!window.ekyCloudMailPayload) {
    window.ekyCloudMailPayload = {
      template: "costCalculator",
      destinations: ['vincent@eyekandy.com', 'luc@eyekandy.com'],
      data: {
        subject: "[COST CALCULATOR] - New Receipt",
        introText: "Hello, please see below for the receipt information",
        content: {
          services: {
            "Home Page Banner": { total: "not selected" },
            "AR/3D Model Production": { total: "not selected" },
            "AR Shop In Shop": { total: "not selected" },
            "Popular Products Feature Spot": { total: "not selected" },
            "Popular AR Avatars Feature Spot": { total: "not selected" },
            "AR Avatar": { total: "not selected" },
            "One To One Live Video Shopping": { total: "not selected" },
            "VR Experience": { total: "not selected" },
            "Total Cost": {},
          },
          details: {
            Brand: "",
            "Contact name": "",
            Email: "",
            "Phone number": "",
          },
        },
      },
    };
  }

  // console.log(window.ekyCloudMailPayload);

  //
  //  :step 2:
  //  Update the object
  window.ekyCloudMailPayload.data.content[type][service] =
    type === "details" ? object : convertObjectToTitleCase(object);
};

const getEkyCloudMailPayload = () => {
  return window.ekyCloudMailPayload || false;
};

export const sendEkyCloudMailPayload = async () => {
  //
  //  :step 1:
  //  Make sure we have a payload to send
  const payload = getEkyCloudMailPayload();
  if (!payload) {
    return false;
  }

  //
  //  :step 2:
  //  Post request to pass our payload off to the email package
  const response = await fetch(
    "https://0tbg9c0yw6.execute-api.eu-west-2.amazonaws.com/prod/send",
    {
      method: "post",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json", "Authorization": "5B46A0FABAD1600FE2A7086880CF7E99E658857DEC7A440C7F3E5E2453ADD38C" },
    }
  );

  // const data = await response.json()

  //
  //  :step 3:
  //  Return true/false depening on the status of the response
  return response.status === 200;
};

//
//  :helper:
export const formatNmber = (number) => {
  //
  //  Format our number with a currency
  return conformToCurrency(number);
};
