import {
  ServiceDescriptionRow,
  ServicePanelSplit,
  PanelRows,
  PanelInputRows,
} from "../Common/ServiceItem";
import { useSelector, useDispatch } from "react-redux";
import { conformToCurrency } from "../Common/Currency";
import { AddARShopInShop, AddARAvatar } from "./ARShopInShopSlice";

import img from "../../Images/ARShopInShop-1.png";
import { ImgWrapper } from "../../Images/index";

const ARShopInShopPanel = () => {
  const {
    ARShopQuantity,
    ARAvatarQuantity,
    ARShopOneTimeCost,
    ARAvatarOneTimeCost,
    AnnualCost,
    total,
  } = useSelector((state) => state.ARShopInShopReducer);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(AddModelAmount(50));
  // }, []);

  const PaneInputArray = [
    {
      label: "Number of AR Shop in Shop",
      value: ARShopQuantity,
      onChange: (ev) => dispatch(AddARShopInShop(ev.target.value)),
    },
    {
      label:
        "Number of Help Me Choose Avatars to Produce for the AR Shop in Shop",
      value: ARAvatarQuantity,
      onChange: (ev) => dispatch(AddARAvatar(ev.target.value)),
    },
  ];
  const PanelArray = [
    {
      label:
        "One time development and installation costs of the AR Shop in Shop",
      value: conformToCurrency(ARShopOneTimeCost),
    },
    {
      label: "One time development Cost per Help Me Choose AR Avatar",
      value: conformToCurrency(ARAvatarOneTimeCost),
    },
    {
      label: "Annual Cost",
      value: conformToCurrency(AnnualCost),
    },
    {
      label: "Total Cost",
      value: conformToCurrency(total),
    },
  ];
  return (
    <>
      <PanelInputRows PanelArray={PaneInputArray} />
      <PanelRows PanelArray={PanelArray} />
    </>
  );
};

export const ARShopInShop = () => {
  return (
    <div>
      <ServiceDescriptionRow>
        Design, build and enablement of an AR Shop in Shop for your brand in the
        Augmented Reality Showcase
      </ServiceDescriptionRow>
      <ServicePanelSplit
        item1={<ImgWrapper src={img} />}
        item2={<ARShopInShopPanel />}
      />
    </div>
  );
};
