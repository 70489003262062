import { createSlice } from "@reduxjs/toolkit";
import {
  formatNmber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";

export const initialState = {
  totalUnitCost: 24950,
  costPerAR3DModel: 499,
  numberOfAR3DModels: 0,
  syndicationCost: 5988,
  total: 0,
  averageCostPerProductPerMonth: 0,
};

updateWindowPayload("services", "AR/3D Model Production", {
  total: formatNmber(initialState.total),
  totalUnitCost: formatNmber(initialState.totalUnitCost),
  costPerAR3DModel: formatNmber(initialState.costPerAR3DModel),
  numberOfAR3DModels: initialState.numberOfAR3DModels,
  syndicationCost: formatNmber(initialState.syndicationCost),
  averageCostPerProductPerMonth: formatNmber(
    initialState.averageCostPerProductPerMonth
  ),
});

const AR3RModelSlice = createSlice({
  name: "AR3DModel",
  initialState,
  reducers: {
    AddModelAmount(state, amount) {
      state.numberOfAR3DModels = amount.payload;
      state.totalUnitCost = state.numberOfAR3DModels * state.costPerAR3DModel;
      state.total = state.totalUnitCost + state.syndicationCost;
      state.averageCostPerProductPerMonth = Math.ceil(
        state.total / 12 / state.numberOfAR3DModels
      );

      //
      //  Update the window payload
      updateWindowPayload("services", "AR/3D Model Production", {
        total: formatNmber(state.total),
        totalUnitCost: formatNmber(state.totalUnitCost),
        costPerAR3DModel: formatNmber(state.costPerAR3DModel),
        numberOfAR3DModels: state.numberOfAR3DModels,
        syndicationCost: formatNmber(state.syndicationCost),
        averageCostPerProductPerMonth: formatNmber(
          state.averageCostPerProductPerMonth
        ),
      });
    },
  },
});

export const { AddModelAmount } = AR3RModelSlice.actions;
export default AR3RModelSlice.reducer;
