//  :react & redux:
// import React from "react";
import React, { useState } from "react";
import { createNumberMask } from "./Currency";
import MaskedInput from "react-text-mask";
import { ARShowcaseDeployment, ProductPagesDeployment } from "../../svgs/Svgs";
import { InputScaleWrapper } from "./input";

const GraphicWrapper = ({ icon, copy }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ height: 80, width: 80 }}>{icon}</div>
      {copy}
    </div>
  );
};

const ARShowcaseGraphic = () => {
  return (
    <GraphicWrapper icon={<ARShowcaseDeployment />} copy={"AR Showcase"} />
  );
};

const ProductPageGraphic = () => {
  return (
    <GraphicWrapper icon={<ProductPagesDeployment />} copy={"Product Pages"} />
  );
};

export const Divider = () => <div style={{ marginBottom: 8 }} />;

export const ValueDecorator = ({ children }) => {
  return (
    <div
      style={{
        borderRadius: 8,
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: "white",
        color: "black",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        flexBasis: "50%",
      }}
    >
      {children}
    </div>
  );
};

export const PanelRow = ({ children }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    {children}
  </div>
);

export const ServicePanelSplit = ({ item1, item2 }) => (
  <ServiceItemWrapper className="bg-white rounded-lg flex text-black mb-5">
    <TwoColumnSplit
      item1={item1}
      item2={<ServiceBluePanel>{item2}</ServiceBluePanel>}
    />
  </ServiceItemWrapper>
);

export const ServiceBluePanel = ({ children }) => {
  return (
    <div
      style={{
        padding: 30,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#10b6eb",
        borderRadius: 8,
        height: "100%",
        width: "100%",
        color: "white",
      }}
    >
      {children}
    </div>
  );
};

export const ServiceItemWrapper = ({ children, onClick, className }) => {
  className = className
    ? className
    : "bg-white rounded-lg flex text-black mb-5";
  return (
    <div
      style={{
        height: "100%",
        flexGrow: 1,
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      }}
      onClick={onClick}
      className={className}
    >
      {children}
    </div>
  );
};

const TitleText = ({ children }) => (
  <div
    style={{
      paddingTop: 30,
      paddingBottom: 30,
      paddingLeft: 30,
      userSelect: "none",
      flexGrow: 1,
    }}
  >
    {children}
  </div>
);

export const TwoColumnSplit = ({ item1, item2 }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "stretch",
        flexGrow: 1,
        alignItems: "stretch",
      }}
    >
      <div style={{ width: "50%", marginRight: 10 }}>{item1}</div>
      <div style={{ width: "50%" }}>{item2}</div>
    </div>
  );
};

export const ServiceItem = ({
  copy,
  ServiceComponent,
  Icon,
  productPage = false,
}) => {
  const [clicked, setClicked] = useState(false);
  return (
    <>
      <div style={{ display: "flex" }}>
        <ServiceItemWrapper
          onClick={() => {
            setClicked(!clicked);
          }}
        >
          <div
            style={{ cursor: "pointer", flexGrow: 1 }}
            className="flex justify-between"
          >
            <RowIcon Icon={Icon} />
            <TitleText>{copy}</TitleText>
            <CrossMinusIcon isMinus={!clicked} />
          </div>
        </ServiceItemWrapper>

        {clicked && (
          <div
            className="flex justify-between mb-5 rounded-lg"
            style={{
              width: 300,
              marginLeft: 10,
              paddingRight: 30,
              backgroundColor: "#10b6eb",
              color: "white",
              flexBasis: "50%",
              boxShadow:
                "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
            }}
          >
            <TitleText>Service Deployment Location </TitleText>
            <ARShowcaseGraphic />
            {productPage && <ProductPageGraphic />}
          </div>
        )}
      </div>
      {clicked && <ServiceComponent />}
    </>
  );
};

const CrossMinusIcon = ({ isMinus }) => (
  <RowIconWrapper>
    <p style={{ color: "#10b6eb", fontSize: 30 }}>{isMinus ? "+" : "-"}</p>
  </RowIconWrapper>
);
const RowIconWrapper = ({ children }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: 100,
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: 50,
        }}
      >
        {children}
      </div>
    </div>
  </div>
);

const RowIcon = ({ Icon }) => (
  <RowIconWrapper>
    <Icon />
  </RowIconWrapper>
);

export const PanelRowLabel = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: 300,
      }}
    >
      {children}
    </div>
  );
};

export const PanelLabelValueRow = ({ label, children }) => {
  return (
    <PanelRow>
      <PanelRowLabel>{label}</PanelRowLabel>
      <ValueDecorator>{children}</ValueDecorator>
    </PanelRow>
  );
};

export const PanelLabelRow = ({ label, children }) => {
  return (
    <PanelRow>
      <PanelRowLabel>{label}</PanelRowLabel>
      {children}
    </PanelRow>
  );
};

export const PanelRows = ({ PanelArray }) => {
  return PanelArray.map((itm) => (
    <div key={itm.label}>
      <PanelLabelValueRow label={itm.label}>{itm.value}</PanelLabelValueRow>
      <Divider />
    </div>
  ));
};

export const PanelInputRows = ({ PanelArray }) => {
  return PanelArray.map((itm) => (
    <div key={itm.label}>
      <PanelRow>
        <PanelRowLabel>{itm.label}</PanelRowLabel>
        <ValueDecorator>
          <InputScaleWrapper style={{ display: "flex", flexGrow: "1" }}>
            <MaskedInput
              value={itm.value}
              style={{
                textAlign: "center",
                borderWidth: 2,
                flexGrow: "1",
                marginLeft: 30,
                marginRight: 30,
                borderRadius: 8,
              }}
              placeholder="0"
              onChange={itm.onChange}
              mask={createNumberMask({
                prefix: "",
                includeThousandsSeparator: false,
              })}
            />
          </InputScaleWrapper>
        </ValueDecorator>
      </PanelRow>
      <Divider />
    </div>
  ));
};

export const ServiceDescriptionRow = ({ children }) => (
  <ServiceItemWrapper>
    <div style={{ padding: 30 }}>
      <div>{children}</div>
    </div>
  </ServiceItemWrapper>
);
