import {
  ServiceDescriptionRow,
  ServicePanelSplit,
  PanelRows,
  PanelInputRows,
} from "../Common/ServiceItem";
import { useSelector, useDispatch } from "react-redux";
import { conformToCurrency } from "../Common/Currency";
import { AddModelAmount } from "./AR3DModelProductionSlice";
import img from "../../Images/3DARModelProduction-1.png";
import { ImgWrapper } from "../../Images/index";

const AR3DModelPanel = () => {
  const {
    totalUnitCost,
    costPerAR3DModel,
    numberOfAR3DModels,
    syndicationCost,
    total,
    averageCostPerProductPerMonth,
  } = useSelector((state) => state.AR3DModelReducer);
  const dispatch = useDispatch();

  const InputPanelArray = [
    {
      label: "Number of AR 3D/AR Models to produce",
      value: numberOfAR3DModels,
      onChange: (ev) => {
        dispatch(AddModelAmount(ev.target.value));
      },
    },
  ];
  const PanelArray = [
    {
      label: "Cost per 3D/AR Models",
      value: conformToCurrency(costPerAR3DModel),
    },
    {
      label: "Total Model Production Cost",
      value: conformToCurrency(totalUnitCost),
    },
    {
      label: "Annual Syndication Cost",
      value: conformToCurrency(syndicationCost),
    },
    {
      label: "Total Model Production Cost & Annual Syndication Cost",
      value: conformToCurrency(total),
    },
    {
      label: "Average Cost per Product, per month",
      value: conformToCurrency(averageCostPerProductPerMonth),
    },
  ];
  return (
    <>
      <PanelInputRows PanelArray={InputPanelArray} />
      <PanelRows PanelArray={PanelArray} />
    </>
  );
};

export const AR3DModelProduction = () => {
  return (
    <div>
      <ServiceDescriptionRow>
        Production and Syndication of 3D/Augmented Reality models to the site,
        at Product page level and in the AR Showcase.
      </ServiceDescriptionRow>
      <ServicePanelSplit
        item1={<ImgWrapper src={img} />}
        item2={<AR3DModelPanel />}
      />
    </div>
  );
};
