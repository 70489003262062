//
//  :react & redux:
import React, { useEffect, useState } from "react";
// import { Helmet } from "react-helmet";

//
//  :components:
import { Content } from "../../../components/Content";
import { HomePageBanner } from "./ServiceCalculationComponents/HomePageBanner";
import { AR3DModelProduction } from "./ServiceCalculationComponents/AR3DModelProduction";
import { ARShopInShop } from "./ServiceCalculationComponents/ARShopInShop";
import { PopularProductsFeatureSpot } from "./ServiceCalculationComponents/PopularProductsFeatureSpot";
import { PopularARAvatarsFeatureSpot } from "./ServiceCalculationComponents/PopularARAvatarsFeatureSpot";
import { VRExperiences } from "./ServiceCalculationComponents/VRExperience";
import { OneToOneLiveVideoShopping } from "./ServiceCalculationComponents/OneToOneLiveVideoShopping";
import { ARAvatar } from "./ServiceCalculationComponents/ARAvatar";
import { ServiceItemWrapper } from "./Common/ServiceItem";

import { ServiceItem, TwoColumnSplit } from "./Common/ServiceItem";
import { conformToCurrency } from "./Common/Currency";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBrand,
  changeContactName,
  changeEmail,
  changeNumber,
} from "../v2/RecommendedSlice";
import { InputScaleWrapper } from "./Common/input";

//
//  :eky-cloud-mail:
import {
  sendEkyCloudMailPayload,
  updateWindowPayload,
  formatNmber,
} from "../../../libs/ekyCloudMail";
import {
  ARAvatarIcon,
  ARModelProductionIcon,
  ARShopInShopIcon,
  HomePageBannerIcon,
  OneToOneLiveVideoShoppingIcon,
  PopularARAvatarIcon,
  PopularProductsFeatureSpotIcon,
  VirtualExperiencesIcon,
} from "../svgs/Svgs";

async function submit() {
  return await sendEkyCloudMailPayload();
}

const FullWidthSection = ({ children }) => (
  <div className="w-full h-fit flex flex-col justify-center  md:p-9 p-4 text-black">
    {children}
  </div>
);
const Services = [
  {
    title: "Homepage Banner",
    component: HomePageBanner,
    icon: HomePageBannerIcon,
  },
  {
    title: "3D/ AR Model Production and Syndication",
    component: AR3DModelProduction,
    icon: ARModelProductionIcon,
    productPage: true,
  },
  {
    title: "AR Shop in Shop",
    component: ARShopInShop,
    icon: ARShopInShopIcon,
  },
  {
    title: "Popular Products Feature Spot",
    component: PopularProductsFeatureSpot,
    icon: PopularProductsFeatureSpotIcon,
  },
  {
    title: "Popular AR Avatars Feature Spot",
    component: PopularARAvatarsFeatureSpot,
    icon: PopularARAvatarIcon,
  },
  {
    title: "AR Avatar",
    component: ARAvatar,
    icon: ARAvatarIcon,
    productPage: true,
  },
  {
    title: "One to One Live Video Shopping",
    component: OneToOneLiveVideoShopping,
    icon: OneToOneLiveVideoShoppingIcon,
    productPage: true,
  },
  {
    title: "Virtual Experiences",
    component: VRExperiences,
    icon: VirtualExperiencesIcon,
    productPage: true,
  },
];
export const ServicesList = ({ Services }) => {
  return (
    <FullWidthSection>
      {Services.map(({ title, component, icon, productPage }) => {
        return (
          <ServiceItem
            key={title}
            copy={title}
            ServiceComponent={component}
            Icon={icon}
            productPage={productPage}
          />
        );
      })}
    </FullWidthSection>
  );
};

const SummaryItem = ({ copy, buttonText, onClick, disabled = false }) => {
  return (
    <ServiceItemWrapper
      className={
        disabled
          ? "bg-slate-700 rounded-lg flex text-white"
          : "bg-slate-500 rounded-lg flex text-white"
      }
      style={{ height: 300 }}
    >
      <div className="flex justify-between" style={{ flexGrow: 1 }}>
        <div
          style={{
            paddingTop: 30,
            paddingBottom: 30,
            paddingLeft: 30,
            paddingRight: 30,
            userSelect: "none",
            width: 300,
          }}
        >
          {copy}
        </div>
      </div>
      <div
        onClick={disabled ? () => {} : onClick}
        style={{
          backgroundColor: disabled ? "grey" : "white",
          color: disabled ? "white" : "#10b6eb",
          flexBasis: 300,
          display: "flex",
          justifyContent: "center",
          borderRadius: 8,
          cursor: "pointer",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {buttonText}
        </div>
      </div>
    </ServiceItemWrapper>
  );
};

const SummaryPanel = () => {
  const { brand, contactName, email, phoneNumber } = useSelector(
    (state) => state.RecommendedReducer
  );

  useEffect(() => {
    updateWindowPayload("details", "Brand", brand);
    updateWindowPayload("details", "Contact name", contactName);
    updateWindowPayload("details", "Email", email);
    updateWindowPayload("details", "Phone number", phoneNumber);
  }, [brand, contactName, email, phoneNumber]);
  const dispatch = useDispatch();
  return (
    <div
      style={{
        padding: 30,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#10b6eb",
        borderRadius: 8,
        width: "100%",
        height: "100%",
        color: "white",
      }}
    >
      {[
        {
          label: "Brand",
          value: brand,
          onChange: (ev) => dispatch(changeBrand(ev.target.value)),
        },
        {
          label: "Contact name",
          value: contactName,
          onChange: (ev) => dispatch(changeContactName(ev.target.value)),
        },
        {
          label: "Email",
          value: email,
          onChange: (ev) => dispatch(changeEmail(ev.target.value)),
        },
        {
          label: "Phone number",
          value: phoneNumber,
          onChange: (ev) => dispatch(changeNumber(ev.target.value)),
        },
      ].map(({ label, value, onChange }) => (
        <div key={label} style={{ display: "flex" }}>
          <div style={{ flexBasis: "30%", paddingBottom: 10 }}>{label}</div>
          <div style={{ flexBasis: "50%", color: "white" }}>
            <InputScaleWrapper>
              <input
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  borderWidth: 2,
                  borderRadius: 8,
                  textAlign: "center",
                }}
                value={value}
                onChange={onChange}
              />
            </InputScaleWrapper>
          </div>
        </div>
      ))}
      <div style={{ paddingTop: 10 }}>
        To ensure an optimised shopper experience, all AR models and Avatars are
        produced via our chosen supplier eyekandy. AR models and Avatars are
        shared across retailers.
      </div>
    </div>
  );
};

const SummaryItems = ({ total }) => {
  const [sending, setsending] = useState(false);
  return (
    <div>
      {[
        {
          copy: "Total cost",
          button: total,
        },
        // { copy: "Generate quote in PDF", button: "Click here" },
        // {
        //   copy: "Send quote for signature via docusign",
        //   button: "Click here",
        // },
      ].map(({ copy, button }, i) => (
        <div key={copy} style={{ marginTop: i > 0 ? 8 : 0 }}>
          <SummaryItem copy={copy} buttonText={button} />
        </div>
      ))}
      <div style={{ marginTop: 8 }} />
      <SummaryItem
        copy="Click Send To send email"
        buttonText={sending ? "Sending" : "Send"}
        disabled={sending}
        onClick={async () => {
          setsending(true);
          const result = await submit();
          console.log(result);
          setsending(false);
        }}
      />
    </div>
  );
};

export const Summary = () => {
  const total = useSelector(
    (state) =>
      state.AR3DModelReducer.total +
      state.homePageReducer.total +
      state.ARShopInShopReducer.total +
      state.PopularARAvatarsFeatureSpotReducer.total +
      state.PopularProductsFeatureSpotReducer.total +
      state.ARAvatarReducer.total +
      state.OneToOneLiveVideoShoppingReducer.total +
      state.VRExperienceReducer.total
  );

  useEffect(() => {
    updateWindowPayload("services", "Total Cost", {
      total: formatNmber(total),
    });
  }, [total]);

  return (
    <FullWidthSection>
      <div style={{ marginBottom: 8 }} />
      <TwoColumnSplit
        item1={
          <>
            <SummaryItems total={conformToCurrency(total)} />
          </>
        }
        item2={<SummaryPanel />}
      />
    </FullWidthSection>
  );
};

export const Reccomended = () => {
  return (
    <Content classes="w-full flex flex-col items-center bg-[#f7f7f7] best-buy-font-med">
      <p className="mb-10 mt-10 text-[37px] best-buy-font-med text-center text-black ">
        Cost Calculator
      </p>
      <ServicesList Services={Services} />
      <Summary />
      <div style={{ marginBottom: 100 }} />
    </Content>
  );
};
