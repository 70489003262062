import { createSlice } from "@reduxjs/toolkit";
import {
  formatNmber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";

const initialState = {
  agentQuantity: 0,
  setupCostPerAgent: 1499,
  monthlyCostPerAgent: 999,
  total: 0,
};

updateWindowPayload("services", "One To One Live Video Shopping", {
  total: formatNmber(initialState.total),
  agentQuantity: initialState.agentQuantity,
});

const OneToOneLiveVideoShoppingSlice = createSlice({
  name: "OneToOneLiveVideoShopping",
  initialState,
  reducers: {
    AddToAgentQuantity: (state, amount) => {
      state.agentQuantity = amount.payload;
      state.total =
        state.agentQuantity * state.setupCostPerAgent +
        state.agentQuantity * state.monthlyCostPerAgent;

      //
      //  Update the window payload
      updateWindowPayload("services", "One To One Live Video Shopping", {
        total: formatNmber(state.total),
        agentQuantity: state.agentQuantity,
      });
    },
  },
});

export const { AddToAgentQuantity } = OneToOneLiveVideoShoppingSlice.actions;
export default OneToOneLiveVideoShoppingSlice.reducer;
