import { createSlice } from "@reduxjs/toolkit";
import {
  formatNmber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";

const initialState = {
  ARShopQuantity: 0,
  ARAvatarQuantity: 0,
  ARShopOneTimeCost: 4999,
  ARAvatarOneTimeCost: 2499,
  AnnualCost: 11988,
  total: 0,
};

updateWindowPayload("services", "AR Shop In Shop", {
  ARShopQuantity: initialState.ARShopQuantity,
  ARAvatarQuantity: initialState.ARAvatarQuantity,
  AnnualCost: formatNmber(initialState.AnnualCost),
  total: formatNmber(initialState.total),
});

const ARShopInShopSlice = createSlice({
  name: "ARShopInShop",
  initialState,
  reducers: {
    AddARShopInShop(state, amount) {
      state.ARShopQuantity = Math.min(amount.payload, 1);
      state.total =
        state.ARShopQuantity * state.ARShopOneTimeCost +
        state.ARAvatarQuantity * state.ARAvatarOneTimeCost +
        state.AnnualCost;

      //
      //  Update the window payload
      updateWindowPayload("services", "AR Shop In Shop", {
        ARShopQuantity: state.ARShopQuantity,
        ARAvatarQuantity: state.ARAvatarQuantity,
        AnnualCost: state.AnnualCost,
        total: formatNmber(state.total),
      });
    },
    AddARAvatar(state, amount) {
      state.ARAvatarQuantity = amount.payload;
      state.total =
        state.ARShopQuantity * state.ARShopOneTimeCost +
        state.ARAvatarQuantity * state.ARAvatarOneTimeCost +
        state.AnnualCost;

      //
      //  Update the window payload
      updateWindowPayload("services", "AR Shop In Shop", {
        ARShopQuantity: state.ARShopQuantity,
        ARAvatarQuantity: state.ARAvatarQuantity,
        AnnualCost: state.AnnualCost,
        total: formatNmber(state.total),
      });
    },
  },
});

export const { AddARShopInShop, AddARAvatar } = ARShopInShopSlice.actions;
export default ARShopInShopSlice.reducer;
