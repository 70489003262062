import { createSlice } from "@reduxjs/toolkit";
import {
  formatNmber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";

const initialState = {
  ARAvatarCost: 2499,
  ARAvatarQuantity: 0,
  syndicationCost: 4788,
  total: 0,
};

updateWindowPayload("services", "AR Avatar", {
  total: formatNmber(initialState.total),
  ARAvatarQuantity: initialState.ARAvatarQuantity,
});

const ARAvatarSlice = createSlice({
  name: "ARAvatar",
  initialState,
  reducers: {
    AddAvatarQuantity: (state, amount) => {
      state.ARAvatarQuantity = amount.payload;
      state.total =
        state.ARAvatarQuantity * state.ARAvatarCost + state.syndicationCost;

      //
      //  Update the window payload
      updateWindowPayload("services", "AR Avatar", {
        total: formatNmber(state.total),
        ARAvatarQuantity: state.ARAvatarQuantity,
        syndicationCost: state.syndicationCost,
      });
    },
  },
});

export const { AddAvatarQuantity } = ARAvatarSlice.actions;
export default ARAvatarSlice.reducer;
