import { createSlice } from "@reduxjs/toolkit";
import {
  formatNmber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";

const initialState = {
  ProductsQuantity: 0,
  NumberOfMonthPerProduct: 1,
  PerProductPerMonth: 999,
  total: 0,
};

updateWindowPayload("services", "Popular Products Feature Spot", {
  //
  //  Update the window payload
  ProductsQuantity: initialState.ProductsQuantity,
  NumberOfMonthPerProduct: initialState.NumberOfMonthPerProduct,
  total: formatNmber(initialState.total),
});

const PopularProductsFeatureSpotSlice = createSlice({
  name: "PopularProductsFeatureSpot",
  initialState,
  reducers: {
    AddProductAmount(state, amount) {
      state.ProductsQuantity = Math.min(amount.payload, 1);
      state.total =
        state.ProductsQuantity *
        state.NumberOfMonthPerProduct *
        state.PerProductPerMonth;
      updateWindowPayload("services", "Popular Products Feature Spot", {
        //
        //  Update the window payload
        ProductsQuantity: state.ProductsQuantity,
        NumberOfMonthPerProduct: state.NumberOfMonthPerProduct,
        total: formatNmber(state.total),
      });
    },
  },
});

export const { AddProductAmount } = PopularProductsFeatureSpotSlice.actions;
export default PopularProductsFeatureSpotSlice.reducer;
