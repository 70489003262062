import { createSlice } from "@reduxjs/toolkit";
import {
  formatNmber,
  updateWindowPayload,
} from "../../../../libs/ekyCloudMail";

const initialState = {
  ARAvatarsQuantity: 0,
  NumberOfMonthPerAvatar: 0,
  PerAvatarPerMonthCost: 999,
  PerARAvatarProductionCost: 2499,
  total: 0,
};

updateWindowPayload("services", "Popular AR Avatars Feature Spot", {
  ARAvatarsQuantity: initialState.ARAvatarsQuantity,
  NumberOfMonthPerAvatar: initialState.NumberOfMonthPerAvatar,
  total: formatNmber(initialState.total),
});

const PopularARAvatarsFeatureSpotSlice = createSlice({
  name: "PopularARAvatarsFeatureSpot",
  initialState,
  reducers: {
    AddAvatarsAmount: (state, amount) => {
      state.ARAvatarsQuantity = Math.min(amount.payload, 1);
      state.total =
        state.ARAvatarsQuantity * state.PerARAvatarProductionCost +
        state.NumberOfMonthPerAvatar * state.PerAvatarPerMonthCost;

      //
      //  Update the window payload
      updateWindowPayload("services", "Popular AR Avatars Feature Spot", {
        ARAvatarsQuantity: state.ARAvatarsQuantity,
        NumberOfMonthPerAvatar: state.NumberOfMonthPerAvatar,
        total: formatNmber(state.total),
      });
    },
    AddAvatarsPerMonth: (state, amount) => {
      state.NumberOfMonthPerAvatar = amount.payload;
      state.total =
        state.ARAvatarsQuantity * state.PerARAvatarProductionCost +
        state.NumberOfMonthPerAvatar * state.PerAvatarPerMonthCost;

      //
      //  Update the window payload
      updateWindowPayload("services", "Popular AR Avatars Feature Spot", {
        ARAvatarsQuantity: state.ARAvatarsQuantity,
        NumberOfMonthPerAvatar: state.NumberOfMonthPerAvatar,
        total: formatNmber(state.total),
      });
    },
  },
});

export const { AddAvatarsAmount, AddAvatarsPerMonth } =
  PopularARAvatarsFeatureSpotSlice.actions;
export default PopularARAvatarsFeatureSpotSlice.reducer;
